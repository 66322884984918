import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as faRegularStar } from '@fortawesome/free-regular-svg-icons';
import { useRating } from '../../../RatingContext';
 
const Rating = () => {
  const { averageRating } = useRating(); // Context se rating le raha hai
 
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (averageRating >= i) {
        stars.push(<FontAwesomeIcon key={i} icon={faSolidStar} style={{ color: '#FFD700', marginRight: '2px' }} />);
      } else if (averageRating >= i - 0.5) {
        stars.push(<FontAwesomeIcon key={i} icon={faStarHalfAlt} style={{ color: '#FFD700', marginRight: '2px' }} />);
      } else {
        stars.push(<FontAwesomeIcon key={i} icon={faRegularStar} style={{ color: '#FFD700', marginRight: '2px' }} />);
      }
    }
    return stars;
  };
 
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {renderStars()}
      <span style={{ marginLeft: '10px', fontWeight: 'bold', color: '#333' }}>
       {averageRating}
      </span>
    </div>
  );
};
 
export default Rating;
 
 