import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useParams } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import Loader from '../../../assets/loader.gif';
import BaseUrl from '../../Server/BaseUrl';

const ActiveBookingList = () => {
  const [cancellationReason, setCancellationReason] = useState('');
  const { userid } = useParams();
  const [state, setState] = useState({
    activeBt: '',
    apiData: [],
    loading: true,
    error: null,
  });

  useEffect(() => {
    fetchData();
  }, [userid, state.activeBt]);

  const fetchData = async () => {
    const API_URL = `${BaseUrl.BaseUrl}/active_booking/${userid}`;

    try {
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      const filteredData = data?.message?.active_bookings?.filter(
        (booking) => booking.status === 'in Progress' || booking.status === 'Completed'
      ) || [];

      setState((prevState) => ({
        ...prevState,
        apiData: filteredData,
        loading: false,
      }));
      autoMarkCompleted(filteredData);
      console.log('data1111', data.message.active_bookings);
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error.message,
        loading: false,
      }));
    }
  };

  const autoMarkCompleted = (bookings) => {
    const currentDate = new Date();
    bookings.forEach((booking) => {
      const exitDate = new Date(booking.exit_date);
      if (exitDate < currentDate && booking.status !== 'Completed') {
        handleMarkComplete(booking.booking_id);
      }
    });
  };

  const handleMarkComplete = async (booking_id) => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/booking_cancel`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          booking_id: booking_id,
          newStatus: 'Completed',
        }),
      });

      const data = await response.json();

      if (data.error) {
        console.error(data.error || 'An error occurred');
      } else {
        console.log(data.message);
        setState((prevState) => ({
          ...prevState,
          apiData: prevState.apiData.map((item) =>
            item.booking_id === booking_id
              ? { ...item, status: 'Completed' }
              : item
          ),
        }));
      }
    } catch (error) {
      console.error('An error occurred while marking the booking complete', error);
    }
  };

  const handleCancelBooking = async (booking_id, closePopup) => {
    console.log('booking', booking_id);

    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/booking_cancel`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          booking_id: booking_id,
          newStatus: "cancel by host"

        }),
      });

      const data = await response.json();

      if (data.error) {
        console.error(data.error || 'An error occurred');
      } else {
        console.log(data.message);
        setState((prevState) => ({
          ...prevState,
          apiData: prevState.apiData.map((item) =>
            item.booking_id === booking_id
              ? { ...item, status: 'cancel by host' }
              : item
          ),
        }));
        closePopup();
      }
    } catch (error) {
      console.error('An error occurred while processing the request', error);
    }
  };

  if (state.loading) {
    return (
      <div style={{ textAlign: 'center', paddingTop: 20 }}>
        <img src={Loader} alt="Loading..." style={{ width: 50, height: 50 }} />
      </div>
    );
  }

  if (state.error) {
    return (
      <div style={{ color: 'red', textAlign: 'center', paddingTop: 20 }}>
        Error: {state.error}
      </div>
    );
  }

  return (
    <div>
      <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Active Booking</h3>
      <div className='topMain' style={{ width: '100%' }}>
        {state.apiData.length === 0 ? (
          <p style={{ textAlign: 'center' }}>No bookings available</p>
        ) : (
          <div className="table-Page" style={{ overflowX: 'auto' }}>
            <table className="table-full" style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center' }}>
              <thead>
                <tr>
                  <th style={{ padding: 10, borderBottom: '1px solid #ddd', minWidth: '200px' }}>SERVICE</th>
                  <th style={{ padding: 10, borderBottom: '1px solid #ddd', minWidth: '200px' }}>Entry Date & Exit Date</th>
                  <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>Total</th>
                  <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>Status</th>
                  <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {state.apiData.map((item, index) => (
                  <tr key={index}>
                    <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                      {item.nights} Night + Rafting <br></br>
                      <span style={{ whiteSpace: 'nowrap', color: '#64748B', fontWeight: '500' }}>  Service by: {item.user_type === 'User' ? `${item.user_name}` : `${item.owner_name}`}.
                        <button style={{ background: 'none', color: '#F15A29', fontWeight: "bold" }}>
                          View Profile
                        </button></span>
                    </td>
                    <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                      {item.entry_date} to {item.exit_date}

                    </td>
                    <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>${item.total_amount}</td>
                    <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                      <button style={{ padding: '5px 10px', backgroundColor: '#D1FAE5', color: '#064E3B', border: 'none', borderRadius: 5, fontWeight: '500' }}>
                        {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                      </button>
                    </td>
                    <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                      <button
                        style={{
                          padding: '5px 10px',
                          backgroundColor: item.status === 'Completed' ? '#E5E7EB' : '#0F172A',
                          color: item.status === 'Completed' ? '#9CA3AF' : '#F8FAFC',
                          border: 'none',
                          borderRadius: 5,
                          fontWeight: '500',
                          cursor: item.status === 'Completed' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => item.status === 'in Progress' && handleMarkComplete(item.booking_id)}
                        disabled={item.status === 'Completed'}
                      >
                        Mark Complete
                      </button>
                    </td>
                    <td style={{ padding: 10, borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                      <Popup
                        shouldCloseOnOverlayClick={false}
                        closeOnDocumentClick={false}
                        trigger={
                          <a>
                            <img style={{ width: 24, height: 24, cursor: 'pointer' }} src={require('../../../assets/threeDot.png')} />
                          </a>
                        }
                        position="bottom right"
                        contentStyle={{ maxHeight: '100px', overflow: 'auto' }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #ddd', padding: 10 }}>
                          {item.status === 'in Progress' && (
                            <Popup
                              shouldCloseOnOverlayClick={false}
                              closeOnDocumentClick={false}
                              trigger={<button style={{ color: '#F87171', background: 'none', border: 'none', cursor: 'pointer', }}>Cancel Booking</button>}
                              modal
                              contentStyle={{ width: '40%', padding: '20px', borderRadius: 10, maxHeight: '200px', overflow: 'auto' }}

                            >
                              {(close) => (
                                <div style={{ textAlign: 'center' }}>
                                  <button onClick={() => close()} style={{ borderRadius: '50%', backgroundColor: '#FECACA', border: 'none', width: 50, height: 50 }}>
                                    <img style={{ width: 24, height: 24 }} src={require('../../../assets/Icon.png')} />
                                  </button>
                                  <h5>Are you sure you want to cancel the booking?</h5>

                                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
                                    <button
                                      style={{
                                        backgroundColor: '#EF4444',
                                        color: 'white',
                                        padding: '5px 15px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s ease',
                                      }}
                                      onMouseEnter={(e) => e.target.style.backgroundColor = '#DC2626'}
                                      onMouseLeave={(e) => e.target.style.backgroundColor = '#EF4444'}
                                      onClick={() => handleCancelBooking(item.booking_id, close)}
                                    >
                                      Yes
                                    </button>

                                    <button
                                      style={{
                                        backgroundColor: '#10B981',
                                        color: 'white',
                                        padding: '5px 15px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s ease',
                                      }}
                                      onMouseEnter={(e) => e.target.style.backgroundColor = '#059669'}
                                      onMouseLeave={(e) => e.target.style.backgroundColor = '#10B981'}
                                      onClick={() => close()}
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          )}
                        </div>
                      </Popup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveBookingList;
