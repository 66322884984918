import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useParams } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import Loader from '../../../assets/loader.gif';
import BaseUrl from '../../Server/BaseUrl';
// import './ActiveBookingUserList.css'

const ActiveBookingUserList = () => {
    const [cancellationReason, setCancellationReason] = useState('');
    const { userid } = useParams();
    const [state, setState] = useState({
        activeBt: '',
        apiData: [],
        loading: true,
        error: null,
        selectedProfileData: null,  // Add this to store profile data
        isProfileLoading: false,    // Add to handle profile loading state
        isProfilePopupOpen: false,
    });

    const [currentPage, setCurrentPage] = useState(1);  // For pagination
    const itemsPerPage = 4; // Show 10 items per page


    useEffect(() => {
        fetchData();
    }, [userid, state.activeBt]);
    console.log("user id ", userid)

    const fetchData = async () => {
        // const API_URL = `https://www.sher9522.caribbeaneaze.com/active_booking_user/${userid}`;
        const API_URL = `${BaseUrl.BaseUrl}/active_booking_user/${userid}`;

        try {
            const response = await fetch(API_URL);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();

            // Filter the data to only include bookings with status 'in progress'
            const filteredData = data?.message?.active_bookings?.filter(
                (booking) => booking.status === 'in Progress' || booking.status === 'Completed'

            ) || [];

            setState((prevState) => ({
                ...prevState,
                apiData: filteredData,
                loading: false,
            }));
            console.log('data1111', data.message.active_bookings);
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                error: error.message,
                loading: false,
            }));
        }
    };



    const handleCancelBooking = async (booking_id, closePopup) => {
        console.log('booking', booking_id);

        try {
            const response = await fetch(`${BaseUrl.BaseUrl}/booking_cancel`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    booking_id: booking_id,
                    newStatus: "cancel by user"

                }),
            });

            const data = await response.json();

            if (data.error) {
                console.error(data.error || 'An error occurred');
            } else {
                console.log(data.message);
                // Update the UI after cancellation
                setState((prevState) => ({
                    ...prevState,
                    apiData: prevState.apiData.map((item) =>
                        item.booking_id === booking_id
                            ? { ...item, status: 'cancel by host' }
                            : item
                    ),
                }));
                closePopup();
            }
        } catch (error) {
            console.error('An error occurred while processing the request', error);
        }
    };



    console.log("user view profile11111======>", state?.selectedProfileData)

    // Calculate paginated data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = state.apiData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(state.apiData.length / itemsPerPage);
    console.log("user view profile======>", currentItems)



    // Function to fetch profile data when "View Profile" is clicked
    const handleViewProfile = (property_id, booking_id) => {
        const selectedItem = currentItems.find(
            (item) => item.property_id === property_id && item.booking_id === booking_id
        );

        if (selectedItem) {
            setState((prevState) => ({
                ...prevState,
                selectedProfileData: selectedItem,
                isProfilePopupOpen: true,
            }));
        }
    };

    const handleCloseProfilePopup = () => {
        setState((prevState) => ({
            ...prevState,
            isProfilePopupOpen: false,  // Close the profile popup
        }));
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    if (state.loading) {
        return (
            <div style={{ textAlign: 'center', paddingTop: 20 }}>
                <img src={Loader} alt="Loading..." style={{ width: 50, height: 50 }} />
            </div>
        );
    }

    if (state.error) {
        return (
            <div style={{ color: 'red', textAlign: 'center', paddingTop: 20 }}>
                Error: {state.error}
            </div>
        );
    }
    // const sanitizedProfileUrl = state?.selectedProfileData?.owner_image?.replace(/^http:\/\/localhost:4000/, '')
    const sanitizedProfileUrl = state?.selectedProfileData?.owner_image?.replace(/^http:\/\/sher9522\.caribbeaneaze\.com/, '');

    return (
        <div>
            <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Active User Booking</h3>
            <div className='topMain' style={{ width: '100%' }}>
                {currentItems.length === 0 ? (
                    <p style={{ textAlign: 'center' }}>No bookings available</p>
                ) : (
                    <div className="table-Page" style={{ overflowX: 'auto' }}>
                        <table className="table-full" style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center' }}>
                            <thead>
                                <tr>
                                    <th style={{ padding: 10, borderBottom: '1px solid #ddd', minWidth: '200px' }}>SERVICE</th>
                                    <th style={{ padding: 10, borderBottom: '1px solid #ddd', minWidth: '200px' }}>Entry Date & Exit Date</th>
                                    <th style={{ padding: 10, borderBottom: '1px solid #ddd', minWidth: '200px' }}>Booking Date and time</th>
                                    <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>Total</th>
                                    <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>Status</th>
                                    <th style={{ padding: 10, borderBottom: '1px solid #ddd' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                                            {item.nights} Night + Rafting <br></br>
                                            <span style={{ whiteSpace: 'nowrap', color: '#64748B', fontWeight: '500' }}>  Service by: {item.user_type === 'User' ? `${item.user_name}` : `${item.owner_name}`}.
                                                {/* {item.user_type === 'User' ? `${item.user_name}` : `${item.owner_name}`}. */}
                                                <button style={{ background: 'none', color: '#F15A29', fontWeight: "bold" }}
                                                    onClick={() => handleViewProfile(item.property_id, item.booking_id)}

                                                >
                                                    View Profile
                                                </button></span>
                                        </td>
                                        <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                                            {item.entry_date} to {item.exit_date}

                                        </td>
                                        <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                                            {item.booking_date}

                                        </td>
                                        <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>${item.total_amount}</td>
                                        <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                                            <button style={{ padding: '5px 10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: 5 }}>
                                                {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                                            </button>
                                        </td>
                                        {/* <td style={{ padding: '20px 10px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                                            <button style={{ padding: '5px 10px', backgroundColor: '#2196F3', color: 'white', border: 'none', borderRadius: 5 }}>
                                                Mark Complete
                                            </button>
                                        </td> */}
                                        <td style={{ padding: 10, borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                                            <Popup
                                                shouldCloseOnOverlayClick={false}
                                                closeOnDocumentClick={false}
                                                trigger={
                                                    <a>
                                                        <img style={{ width: 24, height: 24, cursor: 'pointer' }} src={require('../../../assets/threeDot.png')} />
                                                    </a>
                                                }
                                                position="bottom right"
                                                contentStyle={{ maxHeight: '100px', overflow: 'auto' }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #ddd', padding: 10 }}>
                                                    {item.status === 'in Progress' ? (
                                                        <Popup
                                                            shouldCloseOnOverlayClick={false}
                                                            closeOnDocumentClick={false}
                                                            trigger={
                                                                <button style={{ color: '#F87171', background: 'none', border: 'none', cursor: 'pointer' }}>
                                                                    Cancel Booking
                                                                </button>
                                                            }
                                                            modal
                                                            contentStyle={{ width: '40%', padding: '20px', borderRadius: 10, maxHeight: '200px', overflow: 'auto' }}
                                                        >
                                                            {(close) => (
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <button
                                                                        onClick={() => close()}
                                                                        style={{
                                                                            borderRadius: '50%',
                                                                            backgroundColor: '#FECACA',
                                                                            border: 'none',
                                                                            width: 50,
                                                                            height: 50,
                                                                        }}
                                                                    >
                                                                        <img style={{ width: 24, height: 24 }} src={require('../../../assets/Icon.png')} />
                                                                    </button>
                                                                    <h5>Are you sure you want to cancel the booking?</h5>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            gap: '10px',
                                                                            marginTop: '20px',
                                                                        }}
                                                                    >
                                                                        <button
                                                                            style={{
                                                                                backgroundColor: '#EF4444',
                                                                                color: 'white',
                                                                                padding: '5px 15px',
                                                                                borderRadius: '5px',
                                                                                border: 'none',
                                                                                cursor: 'pointer',
                                                                                transition: 'background-color 0.3s ease',
                                                                            }}
                                                                            onMouseEnter={(e) => (e.target.style.backgroundColor = '#DC2626')}
                                                                            onMouseLeave={(e) => (e.target.style.backgroundColor = '#EF4444')}
                                                                            onClick={() => handleCancelBooking(item.booking_id, close)}
                                                                        >
                                                                            Yes
                                                                        </button>
                                                                        <button
                                                                            style={{
                                                                                backgroundColor: '#10B981',
                                                                                color: 'white',
                                                                                padding: '5px 15px',
                                                                                borderRadius: '5px',
                                                                                border: 'none',
                                                                                cursor: 'pointer',
                                                                                transition: 'background-color 0.3s ease',
                                                                            }}
                                                                            onMouseEnter={(e) => (e.target.style.backgroundColor = '#059669')}
                                                                            onMouseLeave={(e) => (e.target.style.backgroundColor = '#10B981')}
                                                                            onClick={() => close()}
                                                                        >
                                                                            No
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Popup>
                                                    ) : (
                                                        <button
                                                            style={{
                                                                color: '#B91C1C',
                                                                background: 'none',
                                                                border: 'none',
                                                                cursor: 'not-allowed',
                                                                opacity: 0.5,
                                                            }}
                                                            disabled
                                                        >
                                                            Cancel Booking
                                                        </button>
                                                    )}
                                                </div>
                                            </Popup>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {/* Pagination buttons */}
                {totalPages > 1 && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', alignItems: "center" }}>
                        <button
                            className="pagination-button1"
                            style={{
                                padding: '5px 10px',
                                fontSize: '12px',
                                marginRight: '10px',
                                backgroundColor: '#F15A29',
                                color: 'white',
                                border: 'none',
                                cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                            }}
                            onClick={goToPreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <button
                            className="pagination-button1"
                            style={{
                                padding: '5px 10px',
                                fontSize: '12px',
                                backgroundColor: '#F15A29',
                                color: 'white',
                                border: 'none',
                                cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
                            }}
                            onClick={goToNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                )}

                {/* Profile Dialog */}
                <Dialog open={state.isProfilePopupOpen} onClose={handleCloseProfilePopup} PaperProps={{
                    style: {
                        width: '90%',
                        maxWidth: '400px',
                        background: 'white',
                        borderRadius: '60px',
                    },
                }}>
                    <DialogTitle>Property Details </DialogTitle>
                    <DialogContent>
                        {state.isProfileLoading ? (
                            <div style={{ textAlign: 'center', padding: 20 }}>
                                <img src={Loader} alt="Loading..." style={{ width: 50, height: 50 }} />
                            </div>
                        ) : state.selectedProfileData ? (

                            <div className="image-container">
                                <img
                                    src={`${BaseUrl.BaseUrl}${sanitizedProfileUrl}`}
                                    alt=""
                                    className="profile-image"
                                />

                                {/* <p><strong>Property Name:</strong> {state.selectedProfileData.owner_name}</p> */}
                                {/* <p><strong>City:</strong> {state.selectedProfileData.city}</p> */}
                                <p style={{ marginTop: '10px' }}><strong>Owner Name:</strong> {state.selectedProfileData.owner_name}</p>
                                {/* Add more details as needed */}
                            </div>
                        ) : (
                            <p>No profile data available.</p>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseProfilePopup}
                            style={{
                                backgroundColor: '#F87171',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '10px 20px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease',
                                width: "20%",
                                margin: '0 auto'
                            }}
                            onMouseOver={(e) => (e.target.style.backgroundColor = '#E63946')}
                            onMouseOut={(e) => (e.target.style.backgroundColor = '#F87171')}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>
    );
};

export default ActiveBookingUserList;
