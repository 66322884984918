import React, { useState, useEffect } from "react";
import "./sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import BaseUrl from "../Server/BaseUrl";

function Sidebar() {
  const [balance, setBalance] = useState(0);
  const [availableJobs, setAvailableJobs] = useState(0);
  const [earnedThisMonth, setEarnedThisMonth] = useState(0);
  const [expectedEarnings, setExpectedEarnings] = useState(0);
  const [isPaypalPopupVisible, setIsPaypalPopupVisible] = useState(false);
  const [isUserPlusPopupVisible, setIsUserPlusPopupVisible] = useState(false);
  const [paypalId, setPaypalId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [payoutMethod, setPayoutMethod] = useState("");
  const [showPayoutMethodFields, setShowPayoutMethodFields] = useState(true); // State for controlling visibility
  const [withdrawalAmount, setWithdrawalAmount] = useState(""); // New field for withdrawal amount

  useEffect(() => {
    // Replace with your actual API endpoint
    fetch("https://api.example.com/earnings")
      .then((response) => response.json())
      .then((data) => {
        setBalance(data.balance);
        setAvailableJobs(data.availableJobs);
        setEarnedThisMonth(data.earnedThisMonth);
        setExpectedEarnings(data.expectedEarnings);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handlePaypalIconClick = () => {
    setIsPaypalPopupVisible(true);
    setIsUserPlusPopupVisible(false); // Hide User Plus popup
  };

  const handleUserPlusIconClick = () => {
    setIsUserPlusPopupVisible(true);
    setIsPaypalPopupVisible(false); // Hide PayPal popup
  };

  const handleClosePaypalPopup = () => {
    setIsPaypalPopupVisible(false);
    setPaypalId("");
    setWithdrawalAmount("");
    setSuccessMessage("");
  };

  const handleCloseUserPlusPopup = () => {
    setIsUserPlusPopupVisible(false);
    setPayoutMethod("");
    setWithdrawalAmount("");
    setSuccessMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPaypalId("");
    setWithdrawalAmount("");
    try {
      const response = await fetch(
        `${BaseUrl.BaseUrl}/api/paypal-withdrawals/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paypalId,
            withdrawalAmount,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit withdrawal request.");
      }

      // const result = await response.json();
      setSuccessMessage("Withdrawal request submitted successfully.");
      // console.log("Response:", result);
    } catch (error) {
      // console.error("Error submitting withdrawal request:", error);
      setSuccessMessage("Failed to submit withdrawal request.");
    }

    setTimeout(() => {
      setIsPaypalPopupVisible(false);
      setSuccessMessage('');
    }, 2000);
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    // Gather all form inputs
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    // Adjust the structure for Western Union payout
    if (data.payoutMethod === "westernUnion") {
      data.westernUnionDetails = {
        recipientName: data.recipientName,
        recipientAddress: data.recipientAddress,
        country: data.country,
        wuphone: data.wuphone,
      };

      data.bankTransferDetails = null;
    } else if (payoutMethod === "bankTransfer") {
      data.bankTransferDetails = {
        bankName: data.bankName,
        bankAddress: data.bankAddress,
        bankCountry: data.bankCountry,
        accountNumber: data.accountNumber,
        accountType: data.accountType,
        swiftCode: data.swiftCode,
        iban: data.iban,
        routingNumber: data.routingNumber,
      };
      data.westernUnionDetails = null; // Ensure westernUnionDetails is null
    }

    // Add extra fields if needed
    data.withdrawalAmount = withdrawalAmount;

    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/api/payouts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to submit the form.");
      }

      // const result = await response.json();
      setSuccessMessage("Withdrawal request submitted successfully.");
      // console.log("Response:", result);
    } catch (error) {
      // console.error("Error submitting the form:", error);
      setSuccessMessage("Failed to submit withdrawal request.");
    }

    setTimeout(() => {
      setIsUserPlusPopupVisible(false);
      setSuccessMessage('');
    }, 2000);
  };

  const handlePayoutMethodChange = (e) => {
    setPayoutMethod(e.target.value);
    setShowPayoutMethodFields(false);
  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="earningcontent">
        <h2>Earning</h2>
        <div className="balance">
          <p>Personal Balance</p>
          <h1>${balance}</h1>
          <p>Available</p>
          <h4>
            {availableJobs} Jobs (${availableJobs * 50})
          </h4>
          <p>Earned this Month</p>
          <h4>${earnedThisMonth}</h4>
          <p>Expected Earning</p>
          <h4>${expectedEarnings}</h4>
        </div>
        <div className="withdraw-method">
          <h2 style={{ whiteSpace: "nowrap", fontSize: "20px" }}>
            Withdraw Method
          </h2>
          <div className="methods">
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faPaypal}
              size="2x"
              onClick={handlePaypalIconClick}
            />
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faUserPlus}
              size="2x"
              onClick={handleUserPlusIconClick}
            />
          </div>
        </div>
      </div>

      {/* PayPal Popup */}
      {isPaypalPopupVisible && (
        <div className="popup-overlay786">
          <div className="popup-content786">
            <h2>Enter Your PayPal ID and Withdrawal Amount</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={paypalId}
                onChange={(e) => setPaypalId(e.target.value)}
                placeholder="PayPal ID"
                required
              />
              <input
                type="number"
                value={withdrawalAmount}
                onChange={(e) => setWithdrawalAmount(e.target.value)}
                placeholder="Withdrawal Amount"
                required
              />
              <div className="ff">
                <button
                  style={{
                    background: "transparent",
                    color: "black",
                    border: "1px solid black",
                    fontSize: "12px",
                  }}
                  type="submit"
                >
                  Submit
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "black",
                    border: "1px solid black",
                    fontSize: "12px",
                  }}
                  type="button"
                  onClick={handleClosePaypalPopup}
                >
                  Close
                </button>
              </div>
            </form>
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
          </div>
        </div>
      )}

      {/* User Plus Popup */}
      {isUserPlusPopupVisible && (
        <div className="popup-overlaybank786">
          <div className="popup-contentbank786">
            <h2>Host Banking and Payout Details Form</h2>
            <form onSubmit={handleSubmit1}>
              <label htmlFor="fullName">
                Full Name (as it appears on your bank account):
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                placeholder="Full Name:"
                required
                onChange={handlePayoutMethodChange}
              />

              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email Address:"
                required
                onChange={handlePayoutMethodChange}
              />
              <label htmlFor="phone">Phone Number:</label>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Phone Number:"
                required
                onChange={handlePayoutMethodChange}
              />
              <label htmlFor="payoutMethod">Preferred Payout Method:</label>
              <select
                id="payoutMethod"
                name="payoutMethod"
                value={payoutMethod}
                onChange={handlePayoutMethodChange}
                required
              >
                <option value="">Select...</option>
                <option value="bankTransfer">Bank Transfer</option>
                <option value="westernUnion">Western Union</option>
              </select>

              {/* Conditional rendering based on payout method */}
              {payoutMethod === "bankTransfer" && (
                <div id="bankTransferFields">
                  <label htmlFor="bankName">Bank Name:</label>
                  <input type="text" id="bankName" name="bankName" required />

                  <label htmlFor="bankAddress">Bank Address:</label>
                  <input
                    type="text"
                    id="bankAddress"
                    name="bankAddress"
                    required
                  />

                  <label htmlFor="bankCountry">Bank Country:</label>
                  <input
                    type="text"
                    id="bankCountry"
                    name="bankCountry"
                    required
                  />

                  <label htmlFor="accountNumber">Bank Account Number:</label>
                  <input
                    type="text"
                    id="accountNumber"
                    name="accountNumber"
                    required
                  />

                  <label htmlFor="accountType">Account Type:</label>
                  <select id="accountType" name="accountType" required>
                    <option value="checking">Checking</option>
                    <option value="savings">Savings</option>
                  </select>

                  <label htmlFor="swiftCode">SWIFT/BIC Code:</label>
                  <input type="text" id="swiftCode" name="swiftCode" required />

                  <label htmlFor="iban">
                    IBAN (International Bank Account Number):
                  </label>
                  <input type="text" id="iban" name="iban" required />

                  <label htmlFor="routingNumber">
                    Routing Number/Sort Code:
                  </label>
                  <input
                    type="text"
                    id="routingNumber"
                    name="routingNumber"
                    required
                  />
                </div>
              )}

              {payoutMethod === "westernUnion" && (
                <div id="westernUnionFields">
                  <label htmlFor="recipientName">Recipient Name:</label>
                  <input
                    type="text"
                    id="recipientName"
                    name="recipientName"
                    required
                  />

                  <label htmlFor="recipientAddress">Recipient Address:</label>
                  <input
                    type="text"
                    id="recipientAddress"
                    name="recipientAddress"
                    required
                  />
                  <br />

                  <label htmlFor="country">Country:</label>
                  <br />
                  <input type="text" id="country" name="country" required />

                  <label htmlFor="phone">Phone Number:</label>
                  <input type="text" id="phone" name="wuphone" required />
                </div>
              )}

              <select
                id="payoutOption"
                name="payoutOption"
                style={{ width: "100%" }}
                required
              >
                <option value="oneTime">
                  One-time Payout: 24 hours after guest check-in
                </option>
                <option value="monthly">
                  Monthly Payout: 24 hours after guest check-in, and every 25
                  days thereafter if it’s a monthly stay
                </option>
              </select>
              <input
                type="number"
                value={withdrawalAmount}
                onChange={(e) => setWithdrawalAmount(e.target.value)}
                placeholder="Withdrawal Amount"
                required
              />

              <div className="ff">
                <button
                  style={{
                    background: "transparent",
                    color: "black",
                    border: "1px solid black",
                    fontSize: "12px",
                  }}
                  type="submit"
                >
                  Submit
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "black",
                    border: "1px solid black",
                    fontSize: "12px",
                  }}
                  type="button"
                  onClick={handleCloseUserPlusPopup}
                >
                  Close
                </button>
              </div>
            </form>
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Sidebar;

