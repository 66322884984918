import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRating } from '../../RatingContext';
import BaseUrl from '../Server/BaseUrl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';


const ClientProfile = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAll, setShowAll] = useState(false); // State to toggle "View All" functionality

  const { setAverageRating } = useRating();
  const property_Id = useParams();
  const propertyId = property_Id.propertyId;

  useEffect(() => {
    if (!propertyId) {
      setError('Property ID is required.');
      setLoading(false);
      return;
    }

    const fetchReviews = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${BaseUrl.BaseUrl}/property/getReviews`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ property_id: propertyId }),
        });

        if (!response.ok) {
          throw new Error(await response.text());
        }

        const data = await response.json();
        setReviews(data.reviews);
        if (data.reviews.length > 0) {
          // Rating ko number mein convert karo
          const totalRating = data.reviews.reduce(
            (sum, review) => sum + Number(review.rating), // Explicit conversion
            0
          );

          console.log("Total Rating:", totalRating);

          const averageRating = (totalRating / data.reviews.length).toFixed(1);
          console.log("Average Rating Calculated:", averageRating);

          setAverageRating(parseFloat(averageRating));
        } else {
          setAverageRating(0);
        }

      } catch (err) {
        setError(err.message || 'Failed to fetch reviews.');
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [propertyId, setAverageRating]);

  if (loading) {
    return <p>Loading reviews...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  // Determine the reviews to display (either first 5 or all)
  const reviewsToShow = showAll ? reviews : reviews.slice(0, 3);

  return (
    <div style={{ width: '80%', marginLeft: '50px', padding: '20px 0' }}>
      <h2 style={{ fontFamily: 'Arial, sans-serif', fontWeight: '600', marginBottom: '20px' }}>
        Reviews
      </h2>
      {reviews.map((client, index) => (
        <div
          key={index}
          style={{
            borderBottom: '1px solid #e0e0e0',
            paddingBottom: '20px',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <img
              src={client.image || 'https://via.placeholder.com/50'}
              alt={client.first_name}
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                marginRight: '15px',
              }}
            />
            <div>
              <p
                style={{
                  margin: 0,
                  fontFamily: 'Arial, sans-serif',
                  fontWeight: '600',
                  fontSize: '16px',
                }}
              >
                {client.first_name} {client.last_name}
              </p>
              <p
                style={{
                  margin: 0,
                  color: '#888',
                  fontFamily: 'Arial, sans-serif',
                  fontSize: '14px',
                }}
              >
                {new Date(client.date).toLocaleDateString()}
              </p>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <p style={{ color: '#ffd700', fontSize: '16px', marginRight: '5px' }}>
              {'★'.repeat(client.rating)}
              {'☆'.repeat(5 - client.rating)}
            </p>
          </div>
          <p style={{ fontFamily: 'Arial, sans-serif', color: '#555', fontSize: '14px' }}>
            {client.review_text}
          </p>
        </div>
      ))}
      {reviews.length > 3 && (
        <button
          onClick={() => setShowAll(!showAll)}
          style={{
            display: 'block',
            margin: '20px auto',
            padding: '10px 20px',
            fontSize: '16px',
            fontWeight: '600',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '30%',
          }}
        >
          {showAll ? (
            <>
              Show Less <FontAwesomeIcon icon={faChevronUp} />
            </>
          ) : (
            <>
              View All <FontAwesomeIcon icon={faChevronDown} />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default ClientProfile;


