import React, { useState, useEffect } from 'react';
import './dropdown.css';
import BaseUrl from '../../Server/BaseUrl';
 
const Dropdown = () => {
 
  const [booking, setBookingdata] = useState([])
  const [error, setError] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(true);
  const user_id = localStorage.getItem('user_id');
 
  useEffect(() => {
    fetch(`${BaseUrl.BaseUrl}/booking_history/${user_id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json();
      })
      .then(data => {
        setBookingdata(data?.message || []);
 
      })
      .catch(error => {
        setError(error.message);
 
      });
  }, [user_id]);
 
  const getStatusClass = (status) => {
    if (status === "in Progress") return "status-confirmed";
    if (status === "in Progress") return "status-confirmed";
    return "status-cancelled";
  };
 
  const getTimeDifference = (notificationTime) => {
    const currentTime = new Date();
    const notificationDate = new Date(notificationTime);
    const diffInSeconds = Math.floor((currentTime - notificationDate) / 1000);
 
    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  };
  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
 
  return (
    <div className="dropdown"
    onMouseLeave={handleMouseLeave} >
       {isDropdownVisible && (
      <div className="dropdown-content show" id="myDropdown">
        <h3>Booking Notifications</h3>
        {booking?.length === 0 ? (
          <div>No bookings available</div>
        ) : (
          booking?.map((b, index) => (
            <div key={index} className={`notification ${getStatusClass(b?.status)}`}>
              <p>
                Hi<strong> {b?.user_name?.split(' ')[0]}</strong>, your booking date is {' '}
                <strong>{b?.
                  entry_date
                }</strong> to{' '}<strong>{b?.
                  exit_date}</strong>.
              </p>
              <small className="notification-time" style={{color:'gray'}}>
                {getTimeDifference(b?.booking_date)}
              </small>
            </div>
          ))
        )}
      </div>
       )}
    </div>
  );
};
 
export default Dropdown;
 
 