import React, { useState, useEffect, useRef } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import HomePop from "./HomePop";
import './crousel.css';
import { Link } from 'react-router-dom';
import CountryList from 'react-select-country-list';
import Select, { components } from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import { getCountries, getCities } from "countries-cities";
import Flag from 'react-world-flags';
// import 'react-select/dist/react-select.css';
import {
  Box,

  TextField,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
// import CloseIcon from '@mui
const Crousel = ({ CallBackFun }) => {
  const [selectedCountry2, setSelectedCountry2] = useState('');
  const [tropicalCountries, setTropicalCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedPropertyType, setSelectedPropertyType] = useState('');
  const [persons, setPersons] = useState('Add Person');
  const [counts, setCounts] = useState({
    adults: 0,
    children: 0,
    infants: 0,
    pets: 0,
  });
  const [checkInDate, setCheckInDate] = useState(''); // State for Check-In Date
  const [checkOutDate, setCheckOutDate] = useState(''); // State for Check-Out Date
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [showBox, setShowBox] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);// State for filter
  const guestBoxRef = useRef(null); // Ref for guest-box

  // Close guest-box on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (guestBoxRef.current && !guestBoxRef.current.contains(event.target)) {
        setShowBox(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Fetch country options on mount

  useEffect(() => {
    const countries = CountryList().getData(); // Returns [{ label: 'India', value: 'IN' }, ...]
    const formattedCountries = countries.map((country) => ({
      ...country,
      icon: (
        <ReactCountryFlag
          countryCode={country.value}
          svg
          style={{ marginRight: "10px" }}
        />
      ),
    }));
    setCountryOptions(formattedCountries);
  }, []);

  useEffect(() => {
    const totalPersons = counts.adults + counts.children + counts.infants + counts.pets;
    setPersons(totalPersons);
  }, [counts]);

  useEffect(() => {
    // Check if any filter is applied
    const filterApplied = !!(
      selectedCountry ||
      selectedCity ||
      selectedPropertyType ||
      checkInDate ||
      checkOutDate ||
      counts.adults > 0 ||
      counts.children > 0 ||
      counts.infants > 0 ||
      counts.pets > 0
    );
    setIsFilterApplied(filterApplied);
  }, [
    selectedCountry,
    selectedCity,
    selectedPropertyType,
    checkInDate,
    checkOutDate,
    counts,
  ]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);

    // Fetch cities dynamically using the countries-cities library
    try {
      const cities = getCities(selectedOption.label); // Use country name instead of ISO code
      if (!cities || cities.length === 0) {
        throw new Error("No cities found for this country.");
      }
      const formattedCities = cities.map((city) => ({
        value: city,
        label: city,
      }));
      setCityOptions(formattedCities);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setCityOptions([]); // Reset city options on error
    }

    setSelectedCity(null); // Reset city selection
  };




  const handleIncrement = (type) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [type]: prevCounts[type] + 1,
    }));
  };

  const handleDecrement = (type) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [type]: prevCounts[type] > 0 ? prevCounts[type] - 1 : 0,
    }));
  };

  const clearFilters = () => {
    setSelectedCountry(null);
    setSelectedCity('');
    setSelectedPropertyType('');
    setCounts({
      adults: 0,
      children: 0,
      infants: 0,
      pets: 0,
    });
    setCheckInDate('');
    setCheckOutDate('');
    setPersons('Add Person');
  };


  const handleButtonClick = () => {
    const postData = {
      country: selectedCountry?.value || "",
      city: selectedCity?.value || "",
      property_type: selectedPropertyType || "",
      persons: counts || "",
      check_in_date: checkInDate || '', // Include Check-In Date
      check_out_date: checkOutDate || '' // Include Check-Out Date
    };
    console.log("Callback Data:", postData);
    CallBackFun(postData);
  };


  // const cityOptions = selectedCountry ? caribbeanCities[selectedCountry.value] : [];

  const list = [
    { img: require('../../assets/Country.png'), title: 'Antigua' },
    { img: require('../../assets/bahamas.png'), title: 'Bahamas' },
    { img: require('../../assets/cuba.png'), title: 'Cuba' },
    { img: require('../../assets/dominican republic.png'), title: 'Dominican' },
    { img: require('../../assets/grenada.png'), title: 'Grenada' },
    { img: require('../../assets/haiti.png'), title: 'Haiti' },
    { img: require('../../assets/barbados.png'), title: 'Barbados' },
    { img: require('../../assets/Saint Kitts.png'), title: 'Saint Kitts' },
    { img: require('../../assets/jamaica.png'), title: 'Jamaica' },
    { img: require('../../assets/st lucia.png'), title: 'Saint Lucia' },
    { img: require('../../assets/st vincent and the grenadines.png'), title: 'Saint Vincent' },
    { img: require('../../assets/trinidad and tobago.png'), title: 'Trinidad' },
  ];

  return (
    <div className="carousel-container">

      <div className="carousel-list">
        {list.map((item) => (
          <div className="list-item" key={item.title}>
            <img className="list-item-img" src={item.img} alt={item.title} />
            <label className="list-item-label">{item.title}</label>
          </div>
        ))}

      </div>
      <div className="content-section">
        <h1 className="main-title">A Free Mind To your Journey</h1>
        <div className="Crouseldisplay">
          <div className="filter-container">
            <div className="filter-item">
              <span className="span-spacing">Warm Stay</span>
              <div className="select-container">
                <Select
                  className="select-box-country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  options={countryOptions}
                  getOptionLabel={(option) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {option.icon} {option.label}
                    </div>
                  )}
                  getOptionValue={(option) => option.value}
                  placeholder="Select Country"
                />
              </div>
            </div>
            <div className="filter-item">
              <span className="span-spacing">Select City</span>
              {/* City Dropdown */}
              <Select
                className="select-box-city"
                value={selectedCity}
                onChange={setSelectedCity}
                options={cityOptions}
                placeholder="Select City"

              />

            </div>

            <div className="filter-item" >
              <span className="span-spacing">Select Property Type</span>
              <select
                className="select-box"
                value={selectedPropertyType}
                onChange={(e) => setSelectedPropertyType(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Localeaze">Localeaze</option>
                <option value="Middleaze">Middleaze</option>
                <option value="Higheaze">Higheaze</option>
              </select>
            </div>
            {/* favicon.ico */}

            <div className="check-in">
              <div className="filter-item2" >
                <span className="span-spacing" style={{ marginLeft: '8px' }}>Check In</span><br />
                <input
                  className="input"
                  type="date"
                  value={checkInDate}
                  onChange={(e) => setCheckInDate(e.target.value)}
                  min={new Date().toISOString().split('T')[0]} // Disable past dates
                />
              </div>
              <div className="filter-item2" >
                <span className="span-spacing" style={{ marginLeft: '8px' }}>Check Out</span><br />
                <input
                  className="input"
                  type="date"
                  value={checkOutDate}
                  onChange={(e) => setCheckOutDate(e.target.value)}
                  min={checkInDate || new Date().toISOString().split('T')[0]} // Ensure Check-Out is after Check-In
                />
              </div>

            </div>

            <div className="guest-stay-container"

            >
              <div
                className="filter-item"
                style={{ borderLeft: '1px solid #E5E7Eb', paddingBottom: -5 }}
                onClick={() => setShowBox(!showBox)}
              >
                <span className="span-spacing">Guest Stay</span> <br />
                <input
                  type="number"
                  placeholder="Add Person"
                  className="input-field2"
                  value={persons}
                  readOnly
                // style={{ height: 30, width: 120, padding: 10 }}
                />
              </div>
              {showBox && (
                <div className="guest-box"
                  ref={guestBoxRef}
                  onMouseLeave={() => setShowBox(false)}
                >
                  {['adults', 'children', 'infants', 'pets'].map((type, index) => (
                    <div className="guest-row" key={index}>
                      <span className="guest-label">
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                        <span className="age-range">
                          {type === 'adults' && 'Ages 13 or above'}
                          {type === 'children' && 'Ages 2–12'}
                          {type === 'infants' && 'Under 2'}
                          {type === 'pets' && <a href="#"> Bringing a service animal?</a>}
                        </span>
                      </span>
                      <div className="guest-counter">
                        <button onClick={() => handleDecrement(type)}>-</button>
                        <span>{counts[type]}</span>
                        <button onClick={() => handleIncrement(type)}>+</button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="filter-itemss d-flex justify-content-center">
            <a onClick={handleButtonClick} className="search-button">
              <img src={require('../../assets/search-normal.png')} className="search-icon" alt="search" />
            </a>
          </div>

        </div>

{/* Applied the toggle effect here for Clear filter and Advance Filter */}
        <div className="advance-filter-button">
        {selectedCountry || selectedCity || selectedPropertyType || checkInDate || checkOutDate ? (
    <button
      onClick={clearFilters} // Clear filters on button click
      style={{
        height: 48,
        width: '10%',
        border: 'none',
        borderRadius: 12,
        color: 'white',
        background: 'linear-gradient(95.31deg, #56BBFF 1.59%, #55BBFF 1.6%, #061BEB 97.36%)',
      }}
    >
      Clear Filter
    </button> ) : (
          <Popup
            shouldCloseOnOverlayClick={false}
            closeOnDocumentClick={false}
            trigger={
              <button style={{
                height: 48, width: '10%', border: 'none', borderRadius: 12,
                color: 'white', background: 'linear-gradient(95.31deg, #56BBFF 1.59%, #55BBFF 1.6%, #061BEB 97.36%)'
              }}>
                Advance Filter
              </button>
            }
            modal
            contentStyle={{ height: '900px', overflow: 'auto' }}
          >
            {close => (
              <div>
                <HomePop closeModule={() => close()} />
              </div>
            )}
          </Popup>
    )}
        </div>
      </div>
    </div>
  );
};

export default Crousel;
