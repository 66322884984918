import React, { useState } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import BaseUrl from '../Server/BaseUrl';
import './PassportVarification.css';

function PassportVerification(props) {
  const [country, setCountry] = useState('');
  const [options] = useState(countryList().getData());
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [identityType, setIdentityType] = useState('');
  const user_id = localStorage.getItem('user_id'); // Get user_id from local storage

  // Handle country selection
  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.label); // Extract the label (country name)
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to an array
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    // Filter out files exceeding the size limit
    const validFiles = files.filter((file) => {
      if (file.size > maxFileSize) {
        alert(`File ${file.name} exceeds the size limit of 5MB.`);
        return false;
      }
      return true;
    });

    if (validFiles.length > 5) {
      alert('You can only upload up to 5 images.');
      return;
    }

    setSelectedFiles(validFiles); // Save valid files
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!country) {
      alert('Please select a country.');
      return;
    }

    if (!identityType) {
      alert('Please select an identity type.');
      return;
    }

    if (selectedFiles.length === 0) {
      alert('Please upload at least one image.');
      return;
    }

    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('country', country);
    formData.append('identity_type', identityType);
    selectedFiles.forEach((file) => formData.append('card_image', file)); // Add multiple images

    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/identity_verification`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log('API response:', data);
        props.NextCallBack({ navigationTo: 'List_property', id: 5, bt_type: 'Next' });
      } else {
        console.error('API request failed:', response.status, response.statusText);
        alert(`API request failed: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error('API request error:', error);
      alert(`API request error: ${error.message}`);
    }
  };

  return (
    <div
      className="Passport-ID"
      style={{
        width: '100%',
        padding: 30,
        justifyContent: 'center',
      }}
    >
      <label style={{ marginLeft: 20 }}>Step 4/8</label>
      <h4 style={{ fontSize: 30, fontWeight: '700', marginLeft: 20, color: '#000000' }}>
        Passport/ID Verification
      </h4>
      <label
        className="id-pass"
        style={{
          fontSize: 16,
          fontWeight: '400',
          width: '70%',
          color: '#0F172A',
          marginLeft: 20,
        }}
      >
        Upload government-issued ID documents (up to 5 images). Ensure all images are clear and high-quality.
      </label>
      <div className="Country-opt" style={{ width: '80%', padding: 20 }}>
        <label style={{ color: '#474554' }}>Country</label>
        <br />
        <Select options={options} value={{ label: country, value: country }} onChange={handleCountryChange} />
        <br />
        <input
          style={{ cursor: 'pointer' }}
          type="radio"
          id="passport"
          name="idType"
          value="passport"
          onChange={(e) => setIdentityType(e.target.value)}
        />
        <label style={{ cursor: 'pointer' }}> Passport</label>
        <br />
        <input
          style={{ cursor: 'pointer' }}
          type="radio"
          id="nationalId"
          name="idType"
          value="nationalId"
          onChange={(e) => setIdentityType(e.target.value)}
        />
        <label style={{ cursor: 'pointer' }}>National ID</label>
        <br />
      </div>
      <div style={{ width: '100%', height: '30%', padding: 20 }}>
        <label
          htmlFor="fileInput"
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 114,
            borderWidth: 0.1,
            marginTop: 5,
            borderColor: '#94A3B8',
            color: '#94A3B8',
            border: '1.5px dashed',
            borderRadius: 7,
            cursor: 'pointer',
          }}
        >
          <div htmlFor="fileInput" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <label
              htmlFor="fileInput"
              style={{ fontSize: 16, fontWeight: '600', color: '#94A3B8' }}
            >
              Drag your files or Browse
            </label>
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              multiple // Allow multiple file selection
              onChange={handleFileChange}
            />
            <p style={{ fontSize: 12, fontWeight: '400', color: '#94A3B8' }}>
              Add up to 5 images
            </p>
          </div>
        </label>
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '1%',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <button
          className="back-btn"
          style={{
            width: '9%',
            border: 'none',
            background: 'white',
            color: 'black',
          }}
          onClick={() =>
            props.NextCallBack({
              navigationTo: 'Veri_Phone',
              id: 3,
              currentid: 4,
              bt_type: 'Back',
            })
          }
        >
          Back
        </button>
        <button
          className="next-btn"
          style={{
            color: 'white',
            background: '#F15A29',
            width: '15%',
            borderRadius: 10,
            height: '45px',
            border: 'none',
          }}
          onClick={handleSubmit}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default PassportVerification;
