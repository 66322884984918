import React, { createContext, useContext, useState } from 'react';

const RatingContext = createContext();

export const RatingProvider = ({ children }) => {
    const [averageRating, setAverageRating] = useState(0);

    return (
        <RatingContext.Provider value={{ averageRating, setAverageRating }}>
            {children}
        </RatingContext.Provider>
    );
};

export const useRating = () => useContext(RatingContext);

