const propertyData = {
    user_id: "",
    email:"",
    property_name: "",
    select_view: "",
    property_type: "",
    price_per_night: "",
    guest_count:"",
    bedroom_count: "",
    bathroom_count:"",
    property_description: "",
    property_rules: "",
    country: "",
    state: " ",
    city: " ",
    street_address: " ",
    amenties: " ",
    extra_service: " ",
    rafting_number_of_guest: " ",
    rafting_price: "",
    rafting_description: "",
    exotic_food_number_of_guest: "",
    exotic_food_price: "",
    exotic_food_description: "",
  };
  const propety_images =[]
  
export default {propertyData,propety_images};
  
  