import React, { useState, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useParams } from 'react-router-dom';
import BaseUrl from '../../Server/BaseUrl';

const CustomCalendar = () => {
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);
    const [disabledDates, setDisabledDates] = useState([]);
    const property_Id = useParams();
    const propertyId = property_Id.propertyId;

    const handleSelect = (ranges) => {3
        setDateRange([ranges.selection]);
    };

    useEffect(() => {
        if (propertyId) {
            fetch(`${BaseUrl.BaseUrl}/property_availabilities/${propertyId}`)
                .then((response) => response.json())
                .then((data) => {
                    // Transform availabilityDates into Date objects for the calendar
                    const unavailableDates = (data?.property.availabilityDates || []).map(
                        (date) => new Date(date)
                    );
                    setDisabledDates(unavailableDates);
                })
                .catch((error) => {
                    console.error('Error fetching property data:', error);
                });
        }
    }, [propertyId]);

    return (
        <div className='calendar'>
            {/* <h2 style={{ fontSize: 16, marginTop: 10 }}>Selected Dates:</h2>
            <ul>
                {dateRange.map(({ startDate, endDate }, index) => (
                    <li key={index}>
                        <span>Start Date: {startDate.toDateString()}</span>
                        <span>End Date: {endDate.toDateString()}</span>
                    </li>
                ))}
            </ul> */}

            <DateRange
                ranges={dateRange}
                onChange={handleSelect}
                months={window.innerWidth > 600 ? 1 : 1}
                direction="horizontal"
                disabledDates={disabledDates} 
                minDate={new Date()}// Disable unavailable dates
            />
        </div>
    );
};

export default CustomCalendar;
