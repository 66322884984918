import React, { useState } from 'react';
import { Calendar } from 'react-multi-date-picker';
import "react-multi-date-picker/styles/colors/red.css"; // Optional for theming
import './JobsDetailes.css';
import BaseUrl from '../../Server/BaseUrl';

const JobsDetailes = ({ userData }) => {
    const [value, setValue] = useState(new Date());
    const [availabilityDates, setAvailabilityDates] = useState([]);



    const handleAvailabilityChange = (dates) => {
        setAvailabilityDates(dates);
    };

    const updateAvailabilityDates = async () => {
        // const { user_id, property_id } = userData; // Assuming user_id and property_id are available in userData
        const user_id = localStorage.user_id
        const propertyId = userData?.property_id
        console.log("user data ===========================>", userData?.property_id)

        try {
            if (!availabilityDates.length) {
                alert('Please select at least one date.');
                return;
            }

            const formattedDates = availabilityDates.map(date => {
                const jsDate = date.toDate ? date.toDate() : date;
                return jsDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
            });

            const response = await fetch(`${BaseUrl.BaseUrl}/property/availability`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: user_id,
                    property_id: propertyId,
                    availabilityDates: formattedDates
                })
            });

            const data = await response.json();
            if (response.ok) {
                alert('Availability dates updated successfully');
                setAvailabilityDates([]); // Reset dates after successful update
            } else {
                alert(`Error: ${data.error}`);
            }
        } catch (error) {
            console.error('Error updating availability dates:', error);
            alert('An error occurred while updating availability dates');
        }
    };

    return (
        <div style={{ marginTop: 20 }}>
            <div>
                <label style={{ fontSize: 21, fontWeight: '600', marginLeft: 25 }}>Stats</label>
                <ul style={{ listStyleType: 'none', display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                    <div className='balance_1'>
                        <li style={{ fontSize: 20, fontWeight: '500', color: '#0F172A', marginTop: 10 }}>
                            Balance
                        </li>
                        <div style={{ display: 'flex' }}>
                            <li style={{
                                fontSize: 28,
                                fontWeight: '600',
                                color: '#047857'
                            }}>${userData?.completed_job_amount}</li>
                        </div>
                    </div>
                </ul>
                <ul style={{ listStyleType: 'none', display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                    <div className='balance_1'>
                        <li style={{ fontSize: 20, fontWeight: '500', color: '#0F172A', marginTop: 10 }}>
                            Active Jobs
                        </li>
                        <div style={{ display: 'flex' }}>
                            <label style={{ fontSize: 20 }}>{userData?.activeJob} jobs </label>
                            <li style={{
                                fontSize: 20,
                                fontWeight: '500',
                                color: '#047857'
                            }}>   (${userData?.active_job_amount})
                            </li>
                        </div>
                    </div>
                </ul>
                <ul style={{ listStyleType: 'none', display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                    <div className='balance_1'>
                        <li style={{ fontSize: 20, fontWeight: '500', color: '#0F172A', marginTop: 10 }}>
                            Earned this Month
                        </li>
                        <div style={{ display: 'flex' }}>
                            <li style={{
                                fontSize: 22,
                                fontWeight: '500',
                                color: '#047857'
                            }}> (${userData?.current_month_earning})
                            </li>
                        </div>
                    </div>
                </ul>
                <ul style={{ listStyleType: 'none', display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                    <div className='balance_1'>
                        <li style={{ fontSize: 20, fontWeight: '500', color: '#0F172A', marginTop: 10 }}>
                            Expected Earning
                        </li>
                        <div style={{ display: 'flex' }}>
                            <li style={{
                                fontSize: 22,
                                fontWeight: '500',
                                color: '#047857'
                            }}> (${userData?.
                                expected_earnings})
                            </li>
                        </div>
                    </div>
                </ul>
            </div>

            <div className="my-calendar" style={{ width: '99%', padding: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: 19, fontWeight: '500', wordBreak: 'break-all' }}>My Availability Calendar</label>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Calendar
                        multiple
                        value={availabilityDates}
                        onChange={handleAvailabilityChange}
                        className="red" // Customizing color (optional)
                    />
                </div>
                <button
                    onClick={updateAvailabilityDates}
                    style={{ marginTop: 20 }}
                    disabled={availabilityDates.length === 0}>
                    Update Availability
                </button>
            </div>
        </div>
    );
};

export default JobsDetailes;
