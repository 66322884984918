import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import './editform.css';
import HomeHeader from '../HomeHeader';
import BaseUrl from '../../Server/BaseUrl';

const Extra = [
  { id: 1, item: 'Rafting', description: "", number_of_guest: "", price: "" },
  { id: 2, item: 'Exotic Food', description: "", number_of_guest: "", price: "" },
  { id: 3, item: 'Pick and Drop', description: "", number_of_guest: "", price: "" },
  { id: 4, item: 'BBQ', description: "", number_of_guest: "", price: "" },
  { id: 5, item: 'Breakfast', description: "", number_of_guest: "", price: "" },
];

const PropertyForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const propertyData = location.state?.propertyData || {};
  const [selectedExtra, setSelectedExtra] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    property_name: propertyData.property_name || '',
    city: propertyData.city || '',
    country: propertyData.country || '',
    guest_count: propertyData.guest_count || '',
    bedroom_count: propertyData.bedroom_count || '',
    bathroom_count: propertyData.bathroom_count || '',
    price_per_night: propertyData.price_per_night || '',
    select_view: propertyData.select_view || '',
    property_type: propertyData.property_type || '',
    property_description: propertyData.property_description || '',
  });
  const [propertyImages, setPropertyImages] = useState([]);

  useEffect(() => {
    if (propertyData) {
      console.log('Editing property:', propertyData);
    }
  }, [propertyData]);


  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

 // Handle file uploads
 const handleFileChange = (e) => {
  setPropertyImages(e.target.files);
};

  const handleExtraClick = (item) => {
    setSelectedExtra((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((extra) => extra !== item)
        : [...prevSelected, item]
    );
  };

  
  const toggleModal = () => {
    setShowModal((prevShow) => !prevShow);
  };

   // Handle form submission
   const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const form = new FormData();
      form.append('user_id', propertyData.user_id);
      Object.keys(formData).forEach(key => {
        form.append(key, formData[key]);
      });

      // Append files to FormData
      Array.from(propertyImages).forEach(file => {
        form.append('property_images', file);
      });

      // Send API request
      const response = await axios.put(
        `${BaseUrl.BaseUrl}/property/${propertyData.property_id}`,
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      alert('Property updated successfully');
      console.log(response.data);
    } catch (error) {
      console.error('Error updating property:', error);
      alert('Failed to update property');
    }
  };


  return (
    <>
      <HomeHeader />
      <div className="property-form-container">
        <h1>Property Details</h1>
        <form className="property-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="property_name">Property Name:</label>
            <input
              type="text"
              id="property_name"
              name="property_name"
              value={formData.property_name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="city">Location (City & Country):</label>
            <div className="location-container">
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="City"
                required
              />
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="Country"
                required
              />
            </div>
          </div>

          <div className="form-group single-line">
            <div className="form-field">
              <label htmlFor="guest_count">People Count:</label>
              <input
                type="number"
                id="guest_count"
                name="guest_count"
                min="1"
                value={formData.guest_count}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-field">
              <label htmlFor="bathroom_count">Bedroom Count:</label>
              <input
                type="number"
                id="bathroom_count"
                name="bathroom_count"
                min="1"
                value={formData.bathroom_count}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-field">
              <label htmlFor="bathroom_count">Bathroom Count:</label>
              <input
                type="number"
                id="bathroom_count"
                name="bathroom_count"
                min="1"
                value={formData.bathroom_count}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="price_per_night">Price Per Night:</label>
            <input
              type="text"
              id="price_per_night"
              name="price_per_night"
              value={formData.price_per_night}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="select_view">Select View:</label>
            <input
              type="text"
              id="select_view"
              name="select_view"
              value={formData.select_view}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="property_type">Property Type:</label>
            <input
              type="text"
              id="property_type"
              name="property_type"
              value={formData.property_type}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="property_description">Description:</label>
            <textarea
              id="property_description"
              name="property_description"
              rows="4"
              value={formData.property_description}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="propertyImages">Property Images (up to 3):</label>
            <input
            type="file"
            id="propertyImages"
            name="propertyImages"
            accept="image/*"
            multiple
            onChange={handleFileChange}
          />
          </div>

          <div className="form-group">
            <label style={{ fontSize: 20, fontWeight: '550' }}>Extra Service</label>
            <div className="extra-services">
              {Extra.map((item) => (
                <div className="service-ex" key={item.id}>
                  <p
                    style={{
                      background: selectedExtra.includes(item)
                        ? '#F15A29'
                        : '#F3F4F6',
                      width: 'auto',
                      cursor: 'pointer',
                      height: 40,
                      borderRadius: 5,
                      textAlign: 'center',
                      color: selectedExtra.includes(item) ? 'white' : '#6B7280',
                      fontSize: 16,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => handleExtraClick(item)}
                  >
                    {item.item}
                  </p>
                </div>
              ))}
              <div className="add-service-button" onClick={toggleModal}>
                +
              </div>
            </div>
          </div>

          {showModal && (
            <div className="modal">
              <h2>Add New Service</h2>
              {/* Add form fields for new service */}
              <button onClick={toggleModal}>Close</button>
            </div>
          )}

          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  );
};

export default PropertyForm;
