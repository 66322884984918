import React, { Component } from "react";
import HomeHeader from "./HomeHeader";
import Crousel from "./Crousel";
import ScrollDestination from "./ScrollDestination";
import Card from "./Card";
import "./Home.css";
import BaseUrl from "../Server/BaseUrl";
import Modal from "react-modal";
import Cookies from "js-cookie";
import CookiePolicy from "./Cookiepolicy";
import Footer from "./Footer/Footer";
import Feedback from "./Feedback";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      near_by_you: [], // All property data
      filteredProperties: [], // Filtered properties based on user selection
      populer_destinations: [],
      showMainModal: true,
      showPolicyModal: false,
      showCookieModal: !Cookies.get("cookieConsent"),
      hasClosedMainModal: false,
      showFeedbackModal: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    fetch(`${BaseUrl.BaseUrl}/get_all_propertys`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched properties:", data.message.near_by_you);
        this.setState({
          near_by_you: data.message.near_by_you,
          filteredProperties: data.message.near_by_you, // Initially no filter
        });
      })
      .catch((error) => {
        console.error("Error fetching property data:", error);
      });
  };

  NewProperty = (value) => {
    fetch(BaseUrl.BaseUrl + "/most_dest_property/" + value)
      .then((response) => response.json())
      .then((data) => {
        console.log("property:", data);
        this.setState({
          near_by_you: data.message.near_by_you,
          filteredProperties: data.message.near_by_you, // Reset filter on new property
        });
      })
      .catch((error) => {
        console.error("Error fetching property:", error);
      });
  };

  Searchfilter = (postData) => {
    fetch(`${BaseUrl.BaseUrl}/properties/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Post added successfully:", data);
        this.setState({ filteredProperties: data?.data }); // Apply filter
      })
      .catch((error) => {
        console.error("Error adding post:", error);
      });
  };

  acceptCookies = () => {
    Cookies.set("cookieConsent", true, { expires: 365 }); // Set cookie consent for 1 year
    this.setState({ showCookieModal: false });
  };

  declineCookies = () => {
    alert("You have declined cookies. Some features may not work properly.");
    this.setState({ showCookieModal: false });
  };

  openPolicyModal = () => {
    this.setState({ showPolicyModal: true, showCookieModal: false });
  };

  closePolicyModal = () => {
    this.setState({ showPolicyModal: false }, () => {
      if (!Cookies.get("cookieConsent")) {
        this.setState({ showCookieModal: true });
      }
    });
  };

  handleCloseMainModal = () => {
    this.setState(
      {
        showMainModal: false,
        hasClosedMainModal: true,
      },
      () => {
        if (!Cookies.get("cookieConsent")) {
          this.setState({ showCookieModal: true });
        }
      }
    );
  };

  openFeedbackModal = () => {
    this.setState({ showFeedbackModal: true });
  };

  closeFeedbackModal = () => {
    this.setState({ showFeedbackModal: false });
  };

  // Clear filter #1
  clearFilter = () => {
      console.log("Clear Button Clicked");
      window.location.reload(); // Reload the entire page
  };



  render() {
    const {
      showMainModal,
      showCookieModal,
      showPolicyModal,
      hasClosedMainModal,
      showFeedbackModal,
      filteredProperties,
      near_by_you,
    } = this.state;
    const propertiesToDisplay =
      filteredProperties.length > 0 ? filteredProperties : near_by_you;

    return (
      <div style={{ width: "100%", height: "auto" }}>
        <HomeHeader />
        <Crousel
          GetResponceFun={(res) => this.setState({ near_by_you: res })}
          data={this.state.carouselData}
          CallBackFun={(postData) => this.Searchfilter(postData)}
        />
        <ScrollDestination
          props_data={this.state.populer_destinations}
          CallBackFun={(value) => this.NewProperty(value)}
        />
        {/* Passing clearFilter as prop */}
        <Card props_data={propertiesToDisplay} clearFilter={this.clearFilter} />

        {/* Main Modal */}
        <Modal
          isOpen={showMainModal}
          onRequestClose={this.handleCloseMainModal}
          contentLabel="Image Modal"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "60%",
              background: "transparent",
            },
          }}
        >
          <div style={{ width: "90%", textAlign: "center" }}>
            <img
              src={require("../../assets/Some.png")}
              alt="Alert"
              style={{ width: "100%", height: "auto" }}
            />
            <button
              style={{
                width: 100,
                background: "white",
                color: "black",
                marginTop: 10,
                border: "1px solid black",
                borderRadius: 5,
              }}
              onClick={this.handleCloseMainModal}
            >
              Ok
            </button>
          </div>
        </Modal>

        {/* Cookie Consent Modal */}
        {hasClosedMainModal && showCookieModal && (
          <Modal
            isOpen={showCookieModal}
            onRequestClose={this.acceptCookies}
            contentLabel="Cookie Consent Modal"
            style={{
              overlay: {
                zIndex: 1000,
              },
              content: {
                top: "auto",
                left: "50%",
                right: "auto",
                bottom: 0,
                transform: "translateX(-50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              },
            }}
          >
            <div style={{ width: "90%", textAlign: "center" }}>
              <p style={{ textAlign: "center" }}>
                We use cookies to enhance your experience. By continuing, you
                agree to our use of cookies.
              </p>
              <div>
                <button
                  style={{
                    width: 100,
                    background: "white",
                    color: "black",
                    marginTop: 10,
                    border: "1px solid black",
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                  onClick={this.acceptCookies}
                >
                  Accept
                </button>
                <button
                  style={{
                    width: 100,
                    background: "white",
                    color: "black",
                    marginTop: 10,
                    border: "1px solid black",
                    borderRadius: 5,
                  }}
                  onClick={this.declineCookies}
                >
                  Decline
                </button>
              </div>
            </div>
          </Modal>
        )}

        {/* Cookies Policy Modal */}
        <CookiePolicy
          isOpen={showPolicyModal}
          onClose={this.closePolicyModal}
        />

        {/* Feedback Modal */}
        <Modal
          isOpen={showFeedbackModal}
          onRequestClose={this.closeFeedbackModal}
          contentLabel="Feedback Modal"
          style={{
            overlay: {
              zIndex: 1000,
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -45%)",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              overflow: "scroll",
              height: "100vh",
              maxWidth: "600px",
            },
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              onClick={this.closeFeedbackModal}
              style={{
                width: 35,
                height: 35,
                borderRadius: "50%",
                background: "none",
                color: "black",
              }}
            >
              x
            </button>
          </div>
          <Feedback onClose={this.closeFeedbackModal} />
        </Modal>

        {/* Feedback Button */}
        <div onClick={this.openFeedbackModal} className="feedback-button">
          <a>Feedback</a>
        </div>

        <Footer />
      </div>
    );
  }
}
