import React, { Component } from 'react';
import RegistrationHeader from './HomeHeader';
import './Checkout.css';
import BaseUrl from '../Server/BaseUrl';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import './PropertyOwnerProfile/VerifyBooking.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import withNavigation from '../HomeComonent/withNavigation.js';

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: null,
      error: null,
      bookingData: null,
      value: '',
      options: countryList().getData(),
      checkInDate: '',
      checkOutDate: '',
      totalPrice: 0,
      selectedServices: [],
      paypalTransactionId: '',
      cardType: '',
      cardLastFourDigits: '',
      bookingCompleted: false,
      showPaymentOptions: false,
      docType: '',
      fileUploaded: null,
      validationError: '',
      userImages: [],
    };
    this.handleBooking = this.handleBooking.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.showPaymentOptions = this.showPaymentOptions.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleDocTypeChange = this.handleDocTypeChange.bind(this);
    // this.uploadFile = this.uploadFile.bind(this);
  }
  componentDidMount() {
    const bookingData = JSON.parse(localStorage.getItem('bookingData'));
    if (bookingData) {
      this.setState({
        bookingData,
        checkInDate: bookingData.checkInDate,
        checkOutDate: bookingData.checkOutDate,
        totalPrice: bookingData.totalPrice,
        basePrice: bookingData.basePrice,
        numNights: bookingData.numNights,
        selectedServices: bookingData.selectedServices,
        ratePerNight: bookingData.ratePerNight,
      });
    }
  }

  changeHandler(selectedOption) {
    this.setState({ value: selectedOption }, this.clearValidationIfValid);
  }

  handleDocTypeChange(e) {
    this.setState({ docType: e.target.value }, this.clearValidationIfValid);
  }

  handleFileChange(e) {
    this.setState({ fileUploaded: e.target.files[0] }, this.clearValidationIfValid);
  }
  clearValidationIfValid() {
    const { value, docType, userImages } = this.state;
    if (value && docType && userImages.length > 0) {  // Use userImages.length > 0 for file validation
      this.setState({ validationError: '' });
    }
  }
  

  handleBooking() {
    const { checkInDate, checkOutDate, totalPrice, selectedServices, paypalTransactionId, cardType, cardLastFourDigits, value, docType, userImages } = this.state;

    if (!checkInDate || !checkOutDate || !totalPrice || !paypalTransactionId || !value || !docType || userImages.length === 0) {
      console.error('Booking data is incomplete!');
      alert('Please complete all required fields before submitting.');
      return;
    }

    const userId = localStorage.getItem('user_id');
    const data = new FormData();
    data.append('user_id', userId);
    data.append('property_id', localStorage.getItem('property_id'));
    data.append('entry_date', checkInDate);
    data.append('exit_date', checkOutDate);
    data.append('extra_services', JSON.stringify(selectedServices));
    data.append('total_amount', totalPrice);
    data.append('paypal_transaction_id', paypalTransactionId);
    data.append('card_details', cardType);
    data.append('country', value.label);
    data.append('identity_type', docType);
    userImages.forEach((image, index) => {
      data.append(`user_image_${index}`, image);
    });

    fetch(`${BaseUrl.BaseUrl}/booking`, {
      method: 'POST',
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log('Error: ' + data.error);
          alert(data.error);
        } else {
          console.log('Booking successful!', data);
          this.setState({ bookingCompleted: true }, () => {
            setTimeout(() => {
              this.props.navigate(`/booking_user/${userId}`);
            }, 3000);
          });
        }
      })
      .catch((error) => {
        console.error('API request failed:', error);
        alert('Error submitting booking. Please try again.');
      });
  }


  // Method to show the payment options when the "Pay" button is clicked
  showPaymentOptions() {
    const { value, docType, userImages } = this.state;
    if (!value || !docType || userImages.length === 0) {
      this.setState({ validationError: 'Please fill in all fields and upload the required file.' });
      return;
    }
    this.setState({ showPaymentOptions: true, validationError: '' });
  }
  

  render() {
    const { bookingData, showPaymentOptions, validationError, fileUploaded, userImages } = this.state;
    const { value, options, bookingCompleted, totalPrice } = this.state;
    const imageUrl = bookingData && bookingData.propertyImg && bookingData.propertyImg.length > 0
      ? `${BaseUrl.BaseUrl}/Images/${bookingData.propertyImg[0].filename}`
      : require('../../assets/Rectangle 12.png');

    return (
      <div>
        <RegistrationHeader />

        <div className="main-section" style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="side-section" style={{ marginBottom: '2%', width: '30%', border: '1px solid rgba(229, 231, 235, 1)', marginTop: '1.8%', borderRadius: 8 }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 20 }}>
              <div>
                <img
                  style={{ width: 180 }}
                  src={imageUrl}
                  alt="Property"
                /><br />              </div>
              <div className="pay-heading">
                <label>{bookingData ? bookingData.propertyName : ''}</label><br />
                <div>
                  <label style={{ fontSize: '80%' }}> From </label>
                  <label style={{ fontSize: '170%', color: '#F15A29', fontWeight: 750 }}>
                    {bookingData ? `$${bookingData.ratePerNight}` : ''}
                  </label>
                  <label style={{ fontSize: '80%' }}> /Night </label><br />
                </div>
                <img src={require('../../assets/Vector (1).png')} alt="star1" />
                <img src={require('../../assets/Vector (1).png')} alt="star2" />
                <img src={require('../../assets/Vector (1).png')} alt="star3" />
                <img src={require('../../assets/Vector (1).png')} alt="star4" />
                <img src={require('../../assets/Vector (1).png')} alt="star5" />
                <label style={{ marginLeft: 5, marginTop: 2 }}>(200)</label>
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '9%', justifyContent: 'space-between' }}>
              <div style={{ color: '#474554', marginLeft: 22 }}>Check In</div>
              <div style={{ color: '#474554', marginRight: 20 }}>{bookingData ? bookingData.checkInDate : ''}</div>
            </div>
            <div style={{ display: 'flex', marginTop: '2%', justifyContent: 'space-between' }}>
              <div style={{ color: '#474554', marginLeft: 22, marginTop: '2%' }}>Check Out</div>
              <div style={{ color: '#474554', marginTop: '2%', marginRight: 20 }}>{bookingData ? bookingData.checkOutDate : ''}</div>
            </div>
            <h4 style={{ marginLeft: '5.5%', marginTop: 30, fontSize: 18 }}>Billing</h4>
            <div style={{ display: 'flex', marginTop: 15, justifyContent: 'space-between' }}>
              <div style={{ color: '#474554', marginLeft: 22 }}>{bookingData ? `${bookingData.numNights} Nights` : ''}</div>
              <div style={{ color: '#474554', marginRight: 20 }}>{bookingData ? `$${bookingData.basePrice}` : ''}</div>
            </div>
            {/* Conditionally render Taxes if value is non-zero */}
            {bookingData && bookingData.serviceTaxprice !== 0 && (
              <div style={{ display: 'flex', marginTop: 12, justifyContent: 'space-between' }}>
                <div style={{ color: '#474554', marginLeft: 22 }}>Taxes</div>
                <div style={{ color: '#474554', marginRight: 20 }}>{`$${bookingData.serviceTaxprice}`}</div>
              </div>
            )}
            <div style={{ display: 'flex', marginTop: 12, justifyContent: 'space-between' }}>
              <div style={{ color: '#474554', marginLeft: 22 }}>Service Fee</div>
              <div style={{ color: '#474554', marginRight: 20 }}>{bookingData ? `$${bookingData.cancellationprice}` : ''}</div>
            </div>
            <hr style={{ color: '#474554', width: '92%' }} />
            <div style={{ display: 'flex', marginTop: '2%', justifyContent: 'space-between' }}>
              <div style={{ color: '#474554', marginLeft: 22 }}>Subtotal</div>
              <div>
                <label style={{ fontSize: 26, fontWeight: 750, marginRight: 20 }}>${bookingData ? bookingData.totalPrice : ''}</label>
              </div>
            </div>


            {/* Pay Button */}
            <button
              style={{
                marginBottom: 20, border: '1px solid black', width: '92%', marginTop: '3.5%', height: 40, border: 'none',
                background: 'linear-gradient(95.31deg, #56BBFF 1.59%, #55BBFF 1.6%, #061BEB 97.36%)', color: '#FFFFFF', marginLeft: '3.5%', textAlign: 'center', borderRadius: 10
              }}
              onClick={this.showPaymentOptions} // Show payment options when clicked
            >
              Pay ${bookingData ? bookingData.totalPrice : ''}
            </button>
            {/* /* Validation Error Display */}
            {validationError && (
              <div style={{ color: 'red', textAlign: 'center' }}>
                {validationError}
              </div>
            )}

            {/* {/* Payment Options */}
            {showPaymentOptions && (
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5%', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                {bookingCompleted ? (

                  <div>Booking successful! Thank you for booking.</div>


                ) : (
                  <PayPalScriptProvider options={{ "client-id": "AWPh3PQrXOHJQYviQ7qoL1Vk0Z20PRWaSy09L8LT0uQT09Kd9GHfedOAGbJp7Gp6gZNIfd1Jt59JImLN" }}>
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [{
                            amount: {
                              value: totalPrice.toFixed(2),
                            },
                          }],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          alert('Transaction completed by ' + details.payer.name.given_name);
                          this.setState({
                            paypalTransactionId: details.id, // Store PayPal transaction ID

                          }, () => {
                            this.handleBooking(); // Call backend booking function after successful payment
                          });
                        });
                      }}
                    />
                  </PayPalScriptProvider>
                )}
              </div>
            )}
          </div>

          <div className='Payment-Option'>
            <div className='main-heading' style={{ margin: '0 auto', display: 'grid', justifyContent: 'center', marginTop: "5vh" }}>
              <h2 style={{ color: '#000000', fontWeight: 'bold' }}>Verify Before Booking</h2>
            </div>
            <div className='box-width' style={{ width: '60%', padding: 30, justifyContent: 'center', display: 'grid', margin: '0 auto' }}>
              <div style={{ textAlign: 'center' }}>
                <h4 style={{ fontSize: 28, fontWeight: '700', color: '#000000', textAlign: 'center' }}>Passport/ID Verification</h4>
                <label style={{ paddingTop: 5, fontSize: 14, fontWeight: '400', width: '80%', color: '#0F172A' }}>
                  Upload a government-issued ID that clearly shows your full name and complete photo. The document must be a
                  cleared high-resolution color copy of the complete (uncropped) original document with no information obscured
                  by the camera's flash.
                </label>
              </div>

              <div className='media-box' style={{ width: '80%', height: '20%', padding: 20, margin: '0 auto' }}>
                <label style={{ color: '#474554' }}>Country</label>
                <br />
                <Select options={options} value={value} onChange={this.changeHandler} />
                <br />
                <input type="radio" name="docType" value="Passport" onChange={this.handleDocTypeChange} /> Passport <br />
                <input type="radio" name="docType" value="National ID" onChange={this.handleDocTypeChange} /> National ID <br />
              </div>

              <div className='drop-box' style={{ width: '90%', height: '30%', padding: 20, margin: '0 auto' }}>
                <label style={{ color: '#474554' }}>{this.props.title}</label><br />
                <div style={{ width: '100%', padding: 20 }}>
                  <label
                    htmlFor="fileInput"
                    style={{
                      width: '500px',
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      height: 150,
                      borderWidth: 0.1,
                      marginTop: 5,
                      borderColor: '#94A3B8',
                      color: '#94A3B8',
                      border: '1.5px dashed',
                      borderRadius: 7,
                      cursor: 'pointer',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <label style={{ fontSize: 16, fontWeight: '600', color: '#94A3B8' }}>Drag your files or Browse</label>
                      <input name="user_image" type="file" id="fileInput" style={{ display: 'none' }} multiple onChange={(e) => this.setState({ userImages: [...e.target.files] })} />
                      <p>Selected file: </p>
                    </div>
                    <label style={{ fontSize: 12, fontWeight: '400', color: '#94A3B8' }}>Add Both Side Image of Certificate</label>
                  </label>
                  {userImages.length > 0 && (
                    <div>
                      <h5>Uploaded Images:</h5>
                      {userImages.map((image, index) => (
                        <p key={index}>{image.name}</p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default withNavigation(Checkout); // Wrap Checkout with the HOC

