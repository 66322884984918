import React, { Component } from 'react';

class StarRating extends Component {
  handleClick = (rating) => {
    this.props.onRatingChange(rating);
  };

  render() {
    const { rating } = this.props;
    const stars = [1, 2, 3, 4, 5];

    return (
      <div style={{ display: 'flex', cursor: 'pointer' }}>
        {stars.map((star) => (
          <span
            key={star}
            onClick={() => this.handleClick(star)}
            style={{ 
              fontSize: '24px', 
              color: star <= rating ? '#FFD700' : '#e4e5e9', 
              marginRight: '5px'
            }}
          >
            ★
          </span>
        ))}
      </div>
    );
  }
}

export default StarRating;
