import React, { Component } from 'react';
import './personalinformation.css'
 
const Data = [
  {
    title: 'Personal Information',
    desc: 'Enter and Complete',
    img: require('../../assets/profile.png'),
    id: 1,
  },
  {
    title: 'Upload Profile Picture',
    desc: 'Upload Information',
    img: require('../../assets/camera.png'),
    id: 2,
  },
  {
    title: 'Verify Registered Email',
    desc: 'Your Registered Email',
    img: require('../../assets/email__0712.png'),
    id: 3,
  },
  {
    title: 'Passport/ID Verification',
    desc: 'Verify yourself',
    img: require('../../assets/personalcard.png'),
    id: 4,
  },
  {
    title: 'List your Property',
    desc: 'Fill Property info',
    img: require('../../assets/house.png'),
    id: 5,
  },
  {
    title: 'Property Location',
    desc: 'Choose Property Location',
    img: require('../../assets/location.png'),
    id: 6,
  },
  {
    title: 'Property Images',
    desc: 'Upload Property images',
    img: require('../../assets/gallery.png'),
    id: 7,
  },
  {
    title: 'Amenities & Services',
    desc: 'Choose Amenities & Services',
    img: require('../../assets/home-wifi.png'),
    id: 8,
  },
];
 
export default class PersonalInformation extends Component {
  constructor() {
    super();
    this.state = {
      isSidebarOpen: window.innerWidth > 768, // Initially open on larger screens
    };
  }
 
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }
 
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
 
  handleResize = () => {
    const isSidebarOpen = window.innerWidth > 768;
    this.setState({ isSidebarOpen });
  };
 
  toggleSidebar = () => {
    this.setState((prevState) => ({ isSidebarOpen: !prevState.isSidebarOpen }));
  };
 
  render() {
    const { isSidebarOpen } = this.state;
    console.log('checkkk valueeeee ', isSidebarOpen)
 
    return (
      <div id='zeropadd' className={`container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <div id='paddingzero'
          className={`content ${isSidebarOpen ? 'content-open' : ''}`}
          style={{ display: isSidebarOpen || window.innerWidth > 768 ? 'block' : 'block', borderColor: '#94A3B8', width: '100%', borderRight:'1px solid #E2E8F0 ' }}
        >
          {Data.map((data) => (
            <ul style={{ listStyleType: 'none', display: 'flex', justifyContent: 'space-between', paddingLeft: '0', justifyContent: 'center' }}>
 
              {window.innerWidth > 768 ?
                <div style={{ width: '100%', paddingRight: '10px' }}>
                  <li style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    textAlign: 'end',
                    whiteSpace: "nowrap",
                    color: this.props.Highlight.includes(data.id) ? '#000' : '#94A3B8',
                  }}>
                    {data.title}
                  </li>   <p style={{
                    fontSize: '0.875rem',
                    textAlign: 'end',
                    color: this.props.Highlight.includes(data.id) ? '#000' : '#94A3B8',
                  }}>{data.desc}</p>
                </div> : null}
 
 
              <div className='side-icon'>
                <div className='side-profile'
                  style={{
                    width: '2.6125rem',
                    height: '2.6125rem',
                    borderRadius: '1.125rem',
                    marginTop: '0.5rem',
                    backgroundColor: this.props.Highlight.includes(data.id) ? '#FEF0EC' : '#F1F5F9',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    style={{
                      width: '1.25rem',
                      height: '1.25rem',
                      filter: this.props.Highlight.includes(data.id) ? 'invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg) brightness(91%) contrast(95%)' : null,
                    }}
                    src={data.img}
                    alt=""
                  />
 
                </div>
                <div style={{}}>
                  <div className='dotone'
                    style={{
                      borderColor: this.props.Highlight.includes(data.id) ? '#FEF0EC' : '#94A3B8',
                      backgroundColor: this.props.Highlight.includes(data.id) ? '#F15A29' : '#94A3B8',
                    }}
                  ></div>
                </div>
              </div>
            </ul>
          ))}
        </div>
      </div>
    );
  }
}
 
 
 